<template>
    <form @submit.prevent="createInvoice" class="divide-y divide-fibonaki-border">
        <fieldset class="pb-8">
            <header>
                <h3 class="mb-3 text-lg leading-6 font-medium text-gray-900">{{ $tc('general', 1) }}</h3>
            </header>

            <div class="grid grid-cols-12 gap-x-6 gap-y-3">
                <div class="col-span-6">
                    <SelectWithSearch :label="$tc('energy_supplier', 1)" id="energy_supplier_id" optional :options="energySupplierOptions" displayProperty="display_name" valueProperty="id" v-model="invoice.energy_supplier_id" :error="errors.energy_supplier_id" />
                </div>
                <div class="col-span-6">
                    <SelectWithSearch :label="$tc('advisor', 1)" id="advisor_id" optional :options="advisorOptions" displayProperty="display_name" valueProperty="id" v-model="invoice.advisor_id" :error="errors.client_id" />
                </div>
            </div>
        </fieldset>

        <fieldset class="py-8">
            <header>
                <h3 class="mb-3 text-lg leading-6 font-medium text-gray-900">{{ $tc('invoice_detail', 2) }}</h3>
            </header>

            <div class="grid grid-cols-12 gap-x-6 gap-y-3">
                <div class="col-span-6">
                    <Input :label="$tc('invoice_reference', 1)" type="text" id="reference" v-model="invoice.reference" :error="errors.reference" />
                </div>
                <div class="col-span-6">
                    <Input :label="$tc('invoice_date', 1)" type="date" id="invoice_date" v-model="invoice.invoice_date" :error="errors.invoice_date" />
                </div>
            </div>
        </fieldset>

        <fieldset class="py-8">
            <header>
                <h3 class="mb-3 text-lg leading-6 font-medium text-gray-900">{{ $tc('ean_detail', 2) }}</h3>
            </header>
            <div class="grid grid-cols-12 gap-x-6 gap-y-3">
                <div class="col-start-1 col-span-6">
                    <Select :label="$tc('energy_product', 1)" id="energy_product_id" :options="energyProductOptions" displayProperty="display_name" valueProperty="id" v-model="invoice.energy_product_id" :error="errors.energy_product_id" />
                </div>
                <div class="col-start-1 col-span-6">
                    <Select :label="$tc('ean', 1)" id="ean_id" :options="eanOptions" displayProperty="ean_code" valueProperty="id" v-model="invoice.ean_id" :error="errors.client_id" />
                </div>

                <div class="col-start-7 col-span-6">
                    <Select :label="$tc('meter', 1)" id="meter_id" :options="meterOptions" displayProperty="display_name" valueProperty="id" v-model="invoice.meter_id" :error="errors.meter_id" />
                </div>
            </div>
        </fieldset>

        <fieldset class="py-8" v-show="invoice.energy_product_id">
            <header>
                <h3 class="mb-3 text-lg leading-6 font-medium text-gray-900">{{ $tc('consumption_detail', 2) }}</h3>
            </header>
            <div class="grid grid-cols-12 gap-x-6 gap-y-3">
                <div class="col-start-1 col-span-6">
                    <Input :label="$tc('start_date', 1)" type="date" id="start_date" v-model="invoice.start_date" :error="errors.start_date" />
                </div>
                <div class="col-start-7 col-span-6">
                    <Input :label="$tc('end_date', 1)" type="date" id="end_date" v-model="invoice.end_date" :error="errors.end_date" />
                </div>
                <div class="col-start-1 col-span-6" v-if="energy_product === 'electricity'">
                    <Input :label="$tc('kva', 1)" type="number" optional id="kva" v-model="invoice.kva" :error="errors.kva" />
                </div>

                <div class="col-start-1 col-span-6" v-else>
                    <Input :label="$tc('flow_rate', 1)" type="number" step="0.01" optional id="flow_rate" v-model="invoice.flow_rate" :error="errors.flow_rate" />
                </div>

                <div class="col-start-1 col-span-6" v-show="energy_product === 'electricity'">
                    <Input :label="$tc('peak_past_year', 1)" type="number" step="0.01" optional id="peak_year" v-model="invoice.peak_year" :error="errors.peak_year" />
                </div>

                <div class="col-start-7 col-span-6" v-show="energy_product === 'electricity'">
                    <Input :label="$tc('peak_invoice', 1)" type="number" step="0.01" optional id="peak_invoice" v-model="invoice.peak_invoice" :error="errors.peak_invoice" />
                </div>

                <div class="col-start-1 col-span-6" v-show="energy_product === 'electricity'">
                    <Input :label="$tc('reactive_consumption', 1)" type="number" step="0.01" optional id="reactive_consumption" v-model="invoice.reactive_consumption" :error="errors.reactive_consumption" />
                </div>

                <div class="col-start-1 col-span-6">
                    <Input :label="$tc('mono_consumption', 1)" type="number" step="0.00001" optional id="mono_consumption" v-model="invoice.mono_consumption" :error="errors.mono_consumption" />
                </div>

                <div class="col-start-1 col-span-6" v-show="energy_product === 'electricity'">
                    <Input :label="$tc('day_consumption', 1)" type="number" step="0.00001" optional id="day_consumption" v-model="invoice.day_consumption" :error="errors.day_consumption" />
                </div>

                <div class="col-start-7 col-span-6" v-show="energy_product === 'electricity'">
                    <Input :label="$tc('night_consumption', 1)" type="number" step="0.00001" optional id="night_consumption" v-model="invoice.night_consumption" :error="errors.night_consumption" />
                </div>

                <div class="col-start-1 col-span-6" v-show="energy_product === 'electricity'">
                    <Input :label="$tc('exclusive_consumption', 1)" type="number" step="0.00001" optional id="exclusive_consumption" v-model="invoice.exclusive_consumption" :error="errors.exclusive_consumption" />
                </div>

                <div class="col-start-1 col-span-6">
                    <Input :label="$tc('year_consumption', 1)" type="number" step="0.001" optional id="year_consumption" v-model="invoice.year_consumption" :error="errors.year_consumption" />
                </div>
            </div>
        </fieldset>
        <fieldset class="py-8" v-show="invoice.energy_product_id">
            <header>
                <h3 class="mb-3 text-lg leading-6 font-medium text-gray-900">{{ $tc('tariff_detail', 2) }}</h3>
            </header>
            <div class="grid grid-cols-12 gap-x-6 gap-y-3">
                <div class="col-start-1 col-span-6">
                    <Input :label="$tc('fixed_fee', 1)" type="number" step="0.01" optional id="fixed_fee" v-model="invoice.fixed_fee" :error="errors.fixed_fee" />
                </div>
                <div class="col-start-1 col-span-6">
                    <Input :label="$tc('mono_tariff', 1)" type="number" step="0.001" optional id="mono_tariff" v-model="invoice.mono_tariff" :error="errors.mono_tariff" />
                </div>

                <div class="col-start-1 col-span-6" v-show="energy_product === 'electricity'">
                    <Input :label="$tc('day_tariff', 1)" type="number" step="0.001" optional id="day_tariff" v-model="invoice.day_tariff" :error="errors.day_tariff" />
                </div>

                <div class="col-start-7 col-span-6" v-show="energy_product === 'electricity'">
                    <Input :label="$tc('night_tariff', 1)" type="number" step="0.001" optional id="night_tariff" v-model="invoice.night_tariff" :error="errors.night_tariff" />
                </div>

                <div class="col-start-1 col-span-6" v-show="energy_product === 'electricity'">
                    <Input :label="$tc('exclusive_tariff', 1)" type="number" step="0.001" optional id="exclusive_tariff" v-model="invoice.exclusive_tariff" :error="errors.exclusive_tariff" />
                </div>

                <div class="col-start-1 col-span-6" v-show="energy_product === 'electricity'">
                    <Input :label="$tc('gsc', 1)" type="number" step="0.0001" optional id="gsc" v-model="invoice.gsc" :error="errors.gsc" />
                </div>

                <div class="col-start-7 col-span-6" v-show="energy_product === 'electricity'">
                    <Input :label="$tc('chp', 1)" type="number" step="0.0001" optional id="chp" v-model="invoice.chp" :error="errors.chp" />
                </div>

                <div class="col-start-1 col-span-6" v-show="energy_product === 'electricity'">
                    <Input :label="$tc('peak_tariff', 1)" type="number" step="0.01" optional id="peak_tariff" v-model="invoice.peak_tariff" :error="errors.peak_tariff" />
                </div>

                <div class="col-start-7 col-span-6" v-show="energy_product === 'gas'">
                    <Input :label="$tc('gas_transport_cost', 1)" type="number" step="0.001" optional id="transport_cost" v-model="invoice.transport_cost" :error="errors.transport_cost" />
                </div>

                <div class="col-start-7 col-span-6" v-show="energy_product === 'electricity'">
                    <Input :label="$tc('green_energy_tariff', 1)" type="number" step="0.001" optional id="green_energy_add" v-model="invoice.green_energy_add" :error="errors.green_energy_add" />
                </div>
            </div>
        </fieldset>

        <fieldset class="py-8" v-show="invoice.energy_product_id">
            <header>
                <h3 class="mb-3 text-lg leading-6 font-medium text-gray-900">{{ $tc('additional_fee', 2) }}</h3>
            </header>
            <div class="grid grid-cols-12 gap-x-6 gap-y-3">
                <div class="col-start-1 col-span-6">
                    <Input :label="$tc('total_advance', 2)" type="number" step="0.01" optional id="total_advances" v-model="invoice.total_advances" :error="errors.total_advances" />
                </div>

                <div class="col-start-7 col-span-6">
                    <Input :label="$tc('discount', 1)" type="number" step="0.01" optional id="discount" v-model="invoice.discount" :error="errors.discount" />
                </div>

                <div class="col-start-1 col-span-6">
                    <Select :label="$tc('federal_contribution_add', 1)" id="federal_contribution_add" :options="federalContributionAddOptions" displayProperty="display_name" valueProperty="value" v-model="invoice.federal_contribution_add" :error="errors.federal_contribution_add" />
                </div>

                <div class="col-start-7 col-span-6" v-show="energy_product === 'electricity'">
                    <SelectWithSearch :label="$tc('discount_gsc_chp', 1)" id="discount_gsc_chp" :options="discountGscChpOptions" displayProperty="display_name" valueProperty="name" v-model="invoice.discount_gsc_chp" :minLengthForDropdown="3" :error="errors.discount_gsc_chp" />
                </div>

                <div class="col-start-1 col-span-6" v-show="energy_product === 'electricity'">
                    <Input :label="$tc('prosumer_tariff', 1)" type="number" step="0.01" optional id="prosumer_tariff" v-model="invoice.prosumer_tariff" :error="errors.prosumer_tariff" />
                </div>

                <div class="col-start-1 col-span-6" v-show="energy_product === 'electricity'">
                    <Input :label="$tc('netloss_day', 2)" type="number" step="0.00001" optional id="number" v-model="invoice.netlosses_day" :error="errors.netlosses_day" />
                </div>

                <div class="col-start-7 col-span-6" v-show="energy_product === 'electricity'">
                    <Input :label="$tc('netloss_night', 2)" type="number" step="0.00001" optional id="number" v-model="invoice.netlosses_night" :error="errors.netlosses_night" />
                </div>

                <div class="col-start-1 col-span-6">
                    <Input :label="$tc('administration_cost', 1)" type="number" step="0.01" optional id="number" v-model="invoice.administration_cost" :error="errors.administration_cost" />
                </div>

                <div class="col-start-1 col-span-6">
                    <Input :label="$tc('reminder_fee', 1)" type="number" step="0.01" optional id="reminder_fee" v-model="invoice.reminder_fee" :error="errors.reminder_fee" />
                </div>

                <div class="col-start-1 col-span-6">
                    <Input :label="$tc('payment_risk_rate', 1)" type="number" step="0.01" optional id="number" v-model="invoice.payment_risk_rate" :error="errors.payment_risk_rate" />
                </div>
            </div>
        </fieldset>

        <fieldset class="py-8" v-show="invoice.energy_product_id">
            <header>
                <h3 class="mb-3 text-lg leading-6 font-medium text-gray-900">{{ $tc('invoice_total', 2) }}</h3>
            </header>

            <div class="grid grid-cols-12 gap-x-6 gap-y-3">
                <div class="col-span-4">
                    <Input :label="$tc('part_one', 1)" type="number" step="0.01" id="part_one" v-model="invoice.part_one" :error="errors.part_one" />
                </div>
                <div class="col-span-4">
                    <Input :label="$tc('part_two', 1)" type="number" step="0.01" optional id="part_two" v-model="invoice.part_two" :error="errors.part_two" />
                </div>
                <div class="col-span-4">
                    <Input :label="$tc('part_three', 1)" type="number" step="0.01" optional id="part_three" v-model="invoice.part_three" :error="errors.part_three" />
                </div>
                <div class="col-span-4">
                    <Input :label="$tc('part_without_vat', 1)" type="number" step="0.01" id="part_without_vat" v-model="invoice.part_without_vat" :error="errors.part_without_vat" />
                </div>
            </div>
        </fieldset>

        <fieldset class="py-8">
            <ButtonGroup align="right">
                <SubmitButton :label="$tc('submit_invoice', 1)" :submittingLabel="$tc('submitting_invoice', 1)" />
            </ButtonGroup>
        </fieldset>
    </form>
</template>

<script>
    import InvoiceService from '@/services/InvoiceService';
    import ClientService from '@/services/ClientService';
    import ButtonGroup from '../../components/general/ButtonGroup.vue';

    export default {
        data() {
            return {
                invoice: {
                    reference: '',
                    invoice_date: '',
                    start_date: '',
                    end_date: '',
                    energy_product_id: null,
                    energy_supplier_id: null,
                    advisor_id: null,
                    ean_id: null,
                    meter_id: null,
                    peak_year: null,
                    peak_invoice: null,
                    reactive_consumption: null,
                    day_consumption: null,
                    night_consumption: null,
                    year_consumption: null,
                    day_tariff: null,
                    night_tariff: null,
                    gsc: null,
                    chp: null,
                    federal_contribution_add: 1.1,
                    discount_gsc_chp: 'no_discount',
                    netlosses_day: null,
                    netlosses_night: null,
                    part_one: null,
                    part_two: null,
                    part_three: null,
                    part_without_vat: null,
                    kva: null,
                    flow_rate: null,
                    mono_consumption: null,
                    exclusive_consumption: null,
                    fixed_fee_invoice: null,
                    fixed_discount: null,
                    total_advances: null,
                    mono_tariff: null,
                    exclusive_tariff: null,
                    green_energy_add: null,
                    peak_tariff: null,
                    transport_cost: null,
                    total_prosumer_cost: null,
                    reminder_fee: null,
                    admin_cost_rate: null,
                    payment_risk_rate: null,
                    checked: null,
                    estimated_peak: null,
                },
                energyProductOptions: [],
                energySupplierOptions: [],
                meterOptions: [],
                eanOptions: [],
                advisorOptions: [],
                discountGscChpOptions: [
                    { display_name: 'No discount', name: 'no_discount' },
                    { display_name: 'Discount on price', name: 'on_price' },
                    { display_name: 'Discount on consumption', name: 'on_consumption' },
                    { display_name: 'Discount calculated separately', name: 'separately' },
                    { display_name: 'Discount calculated separately per month', name: 'separately_per_month' },
                ],
                federalContributionAddOptions: [
                    { display_name: '1,1% - Energy is for own consumption', value: 1.1 },
                    { display_name: '0,1% - Energy is for reselling', value: 0.1 },
                ],
                errors: {},
            };
        },
        async created() {
            const energyProductsResponse = await InvoiceService.getEnergyProducts();
            this.energyProductOptions = energyProductsResponse.data.energy_products;
            // if (this.energyProductOptions.length > 0) {
            //     this.invoice.energy_product_id = this.energyProductOptions[0].id;
            // }
            const energySuppliersResponse = await InvoiceService.getEnergySuppliers();
            this.energySupplierOptions = energySuppliersResponse.data.energy_suppliers;
            // if (this.energySupplierOptions.length > 0) {
            //     this.invoice.energy_supplier_id = this.energySupplierOptions[0].id;
            // }
            const metersResponse = await InvoiceService.getMeters();
            this.meterOptions = metersResponse.data.meters;
            // if (this.meterOptions.length > 0) {
            //     this.invoice.meter_id = this.meterOptions[0].id;
            // }
            const eansResponse = await ClientService.getEans(this.$route.params.client_id);
            this.eanOptions = eansResponse.data.eans;

            // if (this.eanOptions.length > 0) {
            //     this.invoice.ean_id = this.eanOptions[0].id;
            // }
        },
        methods: {
            async createInvoice() {
                await this.$store.dispatch('clients/createInvoice', { client_id: this.$route.params.client_id, invoice: this.invoice });
                this.$router.push({ name: 'invoiceOverview', params: { client_id: this.$route.params.client_id } });
            },
        },
        components: { ButtonGroup },
        computed: {
            energy_product() {
                if (this.invoice.energy_product_id) {
                    return this.energyProductOptions.filter((item) => item.id === this.invoice.energy_product_id)[0].name;
                }
                return '-';
            },
        },
    };
</script>
